<template>
  <div class="upload-video">
    <el-upload
      class="avatar-uploader"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :http-request="customizeUpload"
      :on-progress="onUploadProgress"
      :accept="accept"
      name="imagelist"
    >
      <div class="upload-box" v-if="imageUrl">
        <el-image :src="imageUrl" class="avatar" fit="contain"></el-image>
      </div>
      <div class="upload-box" v-else>
        <svg
          width="44"
          height="44"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <path d="M.5 0h24v24H.5z"></path>
            <path
              d="M19.595 3c1.052 0 1.905.87 1.905 1.943v10.645c0 1.074-.853 1.944-1.905 1.944L12.5 17.53V19h3a1 1 0 010 2h-8a1 1 0 010-2h3v-1.469H3.405c-1.052 0-1.905-.87-1.905-1.943V4.943C1.5 3.87 2.353 3 3.405 3h16.19zm0 2H3.405v10.532h16.19V5z"
              fill="currentColor"
              fill-rule="nonzero"
            ></path>
            <path fill="currentColor" d="M2.5 12h18v2h-18z"></path>
          </g>
        </svg>
        <div style="font-size: 12px">设备</div>
      </div>
    </el-upload>
    <div style="text-align: center" v-if="percent != 0">
      <el-progress :percentage="percent"></el-progress>
    </div>
  </div>
</template>
<script>
import { getSAuth, getSchool } from "@/utils/auth.js";
import { uploadFilesVideo } from "@/api/common.js";
import env from "@/settings/env.js";
export default {
  name: "UploadImgAndVideo",
  props: ["imageUrl", "ids", "index"],
  data() {
    return {
      headers: {
        Authorization: getSAuth(),
        school: getSchool(),
      },
      imgList: this.imageUrl,
      action: env.apiUrlPrefix + "api/upload/video",
      accept: ".mp4",
      percent: 0,
    };
  },
  methods: {

    customizeUpload(data) {
      const { file } = data;
      this.percent = 0;
      let isLt2M = true;
      let _this = this;

      if (file.type == "video/mp4") {
        var url = URL.createObjectURL(file);

        let videoElement = new Audio(url);

        videoElement.addEventListener("loadedmetadata", function () {
          if (videoElement.duration < 10 || videoElement.duration > 60) {
            _this.$message.error("视频时长不符合限制，时长应为10s-60s内");
          } else {
            isLt2M = file.size / 1024 / 1024 < 30;
            if (!isLt2M) {
              _this.$alert("最大文件大小必须小于30.0MB", "视频大小不正确", {
                confirmButtonText: "确认",
                callback: () => {},
              });
            } else {
                 const uploadProgressEvent = progressEvent => {
      _this.percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
    }
              _this.submit(file,uploadProgressEvent);
            }
          }
        });
      } else {
        this.$message.error("文件格式不正确");
      }
    },


    submit(file,uploadProgressEvent) {
      let formData = new FormData();
      formData.append("imagelist", file);
      uploadFilesVideo(formData,uploadProgressEvent).then((res) => {
        if (res.code == 1) {
          
          this.$emit("uploadVideo", res.data);
        }
      });
    },
    onUploadProgress(event) {
      this.percent = parseInt(event.percent);
    },
  },
};
</script>
<style lang="less" scoped>
.upload-video {
  .upload-box {
    width: 366px;
    height: 205px;
    border: 1px dashed #d8d8d8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #b7b7b7;
    border-radius: 4px;
    background: #fff;
    margin-top: 20px;
  }
  .avatar {
    width: 366px;
    height: 205px;
  }
  .upload-box:hover {
    color: #ee4d2d;
    border-color: #ee4d2d;
  }
}
</style>


      // :on-success="handleAvatarSuccess"
      // :before-upload="beforeAvatarUpload"