<template>
  <div class="editDecorate">
    <div class="top">
      <div>
        <span style="font-size: 14px">当前电脑版店铺装饰</span>
        <el-divider direction="vertical"></el-divider>
        <span style="font-size: 12px; color: #b0b0b0"
          >最后储存时间：{{ fbTime ? fbTime : "--" }}</span
        >
      </div>
      <div>
        <el-button size="medium" @click="handleLook">预览</el-button>
        <el-button size="medium" @click="handleAllSave(0)">保存</el-button>
        <el-button size="medium" @click="handleAllSave(1)" type="primary"
          >发布</el-button
        >
      </div>
    </div>
    <div style="height: 56px; width: 100%"></div>
    <div class="left" :style="{ transform: 'translate(' + value + 'px)' }">
      <!-- 开关按钮 -->
      <div class="fold-box" @click="handleLeftFold">
        <img :src="zheDImg" alt="" />
        <i class="el-icon-arrow-right icons" v-if="leftFoldShow"></i
        ><i class="icons el-icon-arrow-left" v-else></i>
      </div>
      <!-- 左侧组件组合 -->
      <div style="overflow: scroll; height: 100%; width: 100%; padding: 16px">
        <template v-for="(item, index) in typeList">
          <div class="left-templ" v-if="index != 3" :key="index">
            <div class="title">
              <span>{{ item.title }}</span>
              <span @click="handleTemplShow(index)"
                ><i
                  v-if="item.show"
                  class="el-icon-arrow-up"
                  style="cursor: pointer"
                ></i>
                <i v-else class="el-icon-arrow-down" style="cursor: pointer"></i
              ></span>
            </div>
            <div class="left-templ_cell" v-if="item.show == 1">
              <div
                class="left-templ_item"
                v-for="(val, i) in item.data"
                :key="i"
              >
                <el-popover
                  placement="top"
                  width="185"
                  trigger="hover"
                  content="已添加组件的最大数量"
                >
                  <div
                    slot="reference"
                    class="zbc"
                    :style="{ display: val.num >= val.max ? 'block' : 'none' }"
                  ></div>
                </el-popover>

                <div
                  class="img"
                  draggable
                  :data-index="index"
                  :data-type="val.key"
                  :data-title="val.name"
                  :data-data="JSON.stringify(val.data)"
                  @dragstart="dragStart"
                  @dragend="dragEnd"
                >
                  <LeftComponent :keyName="val.key"></LeftComponent>
                </div>
                <div style="font-size: 12px; text-align: center; color: #666">
                  <div
                    style="
                      margin: 10px 0 4px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span>
                      {{ val.name }}
                      <el-popover
                        style="padding: -10px"
                        placement="right"
                        width="200"
                        trigger="hover"
                      >
                        <div style="margin: -2px; font-size: 14px; color: #333">
                          {{ val.desc }}
                        </div>
                        <svg
                          slot="reference"
                          t="1670824981381"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="3210"
                          width="12"
                          height="12"
                          style="margin-left: 2px; margin-bottom: -1px"
                        >
                          <path
                            d="M512 936.746667c234.581333 0 424.746667-190.165333 424.746667-424.746667S746.581333 87.253333 512 87.253333 87.253333 277.418667 87.253333 512 277.418667 936.746667 512 936.746667z m0 64C242.090667 1000.746667 23.253333 781.909333 23.253333 512 23.253333 242.090667 242.090667 23.253333 512 23.253333 781.909333 23.253333 1000.746667 242.090667 1000.746667 512c0 269.909333-218.837333 488.746667-488.746667 488.746667z"
                            p-id="3211"
                          ></path>
                          <path
                            d="M422.826667 436.266667v0.682666a32 32 0 1 1-64-1.28 11.306667 11.306667 0 0 1-0.042667-1.408c0-47.274667 13.269333-86.186667 39.552-116.864 28.8-33.109333 68.48-49.322667 118.741333-49.322666 44.714667 0 80.938667 12.970667 108.544 39.125333 26.453333 24.917333 39.594667 58.624 39.594667 100.352 0 30.293333-9.386667 58.112-27.733333 82.389333-7.68 9.685333-24.234667 25.813333-54.229334 52.949334-14.506667 12.672-24.746667 24.490667-31.146666 35.285333-8.405333 14.506667-12.458667 29.269333-12.501334 45.141333l1.152 19.370667a11.178667 11.178667 0 0 1-11.178666 11.861333h-41.898667a11.178667 11.178667 0 0 1-11.221333-11.178666v-19.370667c0-21.162667 4.437333-40.917333 13.098666-58.197333 10.496-22.485333 32.213333-48.512 63.701334-75.989334 19.584-19.626667 25.728-25.941333 29.781333-31.018666 12.8-16.469333 19.029333-32.810667 19.029333-49.92 0-25.472-7.381333-45.568-21.418666-60.202667-14.762667-14.762667-36.352-22.144-65.578667-22.144-32.64 0-56.021333 10.496-72.106667 31.872-13.525333 16.938667-19.968 41.685333-19.968 75.861333a11.264 11.264 0 0 1-0.170666 2.005334zM512 763.648a32 32 0 1 1 0-64 32 32 0 0 1 0 64z"
                            p-id="3212"
                          ></path>
                        </svg>
                      </el-popover>
                    </span>
                  </div>
                  <div>{{ val.num + "/" + val.max }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 店铺组件容器 -->
    <div class="in">
      <div class="in-card" @dragover="dragover" @drop="drog">
        <!-- <div
          @click="selectType($event, 99)"
          style="border: 2px solid transparent"
          :style="{
            'border-color': activeIndex === 99 ? '#2673dd' : 'transparent',
          }"
        >
          <ShopCover
            :type="2"
            :info="info"
            :categoryDatab="categoryDatab"
            :onlineTime="onlineTime"
            :data-index="0"
            :categoryDataa="categoryDataa"
          ></ShopCover>
          <div
            class="left-tag"
            :style="{
              background: activeIndex === 99 ? '#666' : '#e8e8e8',
              color: activeIndex === 99 ? '#fff' : '#000',
            }"
          >
            店铺封面
          </div>
          <span class="right-form" v-if="activeIndex === 99">
            <RightForm></RightForm>
          </span>
        </div> -->
        <div class="view-content">
          <Draggable
            v-model="viewList"
            :draggable="false"
            style="padding-bottom: 30px"
            :move="onMove"
          >
            <template v-for="(item, index) in viewList">
              <div
                :data-index="index"
                :key="index"
                class="item"
                :style="{
                  'border-color':
                    activeIndex === index ? '#2673dd' : 'transparent',
                  padding: index === 0 ? '0' : '2px',
                }"
                @click="selectType($event, index)"
              >
                <template v-if="item.status && item.status == 2">
                  <div class="wait">
                    <i class="el-icon-plus"></i>
                    将组件放在这里
                  </div>
                </template>
                <template v-else>
                  <span
                    class="left-tag"
                    :style="{
                      background: activeIndex === index ? '#666' : '#e8e8e8',
                      color: activeIndex === index ? '#fff' : '#000',
                    }"
                  >
                    {{ item.title }}
                  </span>
                  <span class="right-tag" v-if="activeIndex === index">
                    <span @click.stop="handleUpWard(index)">
                      <svg
                        t="1669711392248"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1272"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M960.19 552.815L512.086 102.3 63.809 552.815h263.834V921.7H696.53V552.815z"
                          p-id="1273"
                          :fill="index > 1 ? '#666' : '#b8b8b8'"
                        ></path>
                      </svg> </span
                    ><span @click.stop="handleDown(index)">
                      <svg
                        style="transform: rotateX(180deg)"
                        t="1669711392248"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1272"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M960.19 552.815L512.086 102.3 63.809 552.815h263.834V921.7H696.53V552.815z"
                          p-id="1273"
                          :fill="
                            index < viewList.length - 1 && index != 0
                              ? '#666'
                              : '#b8b8b8'
                          "
                        ></path></svg></span
                    ><span
                      @click.stop="hadleItem(item, index)"
                      style="border-top: 1px solid #eee"
                    >
                      <i
                        class="el-icon-delete-solid"
                        style="font-size: 14px; margin-top: 10px"
                        :style="{ color: index !== 0 ? '#666' : '#b8b8b8' }"
                      ></i>
                    </span>
                  </span>
                  <div
                    class="right-form"
                    v-if="activeIndex === index && index != 0"
                  >
                    <RightForm
                      :data="item.data"
                      :index="index"
                      :type="item.type"
                    ></RightForm>
                  </div>
                  <component
                    class="asd"
                    :data="item['data']"
                    :is="typeList[item.index]['data'][item.type]['com']"
                    :type="2"
                    :info="info"
                    :categoryDatab="categoryDatab"
                    :onlineTime="onlineTime"
                    :data-index="0"
                    :categoryDataa="categoryDataa"
                  ></component>
                </template>
              </div>
            </template>
          </Draggable>
        </div>
        <div class="bottom" style="pointer-events: none">
          <BottomGoods> </BottomGoods>
        </div>
      </div>
    </div>
    <!-- 预览弹窗 -->
    <div v-if="dialogTableVisible3" class="view-pop">
      <div class="view-box">
        <div class="title">
          <span>电脑版装饰预览</span>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="dialogTableVisible3 = false"
          ></i>
        </div>
        <div class="view-in">
          <div class="shopee-loading" v-if="ylShow">
            <img class="xzimg" :src="xz" alt="" />
            <img class="simg" :src="s" alt="" />
          </div>
          <div
            v-else
            style="
              width: 1240px;
              pointer-events: none;
              transform: scale(0.8);
              transform-origin: top;
            "
          >
            <template v-for="(item, index) in viewList">
              <div :key="index" :style="{ margin: index == 0 ? 0 : '10px 0' }">
                <component
                  class="asd"
                  :data="item['data']"
                  :is="typeList[item.index]['data'][item.type]['com']"
                  :type="2"
                  :info="info"
                  :categoryDatab="categoryDatab"
                  :onlineTime="onlineTime"
                  :data-index="0"
                  :categoryDataa="categoryDataa"
                ></component>
              </div>
            </template>
            <div class="bottom">
              <BottomGoods> </BottomGoods>
            </div>
          </div>
        </div>
        <div class="view-bottom">
          <el-button
            style="min-width: 80px"
            size="medium"
            type="primary"
            @click="dialogTableVisible3 = false"
            >返回</el-button
          >
        </div>
      </div>
    </div>

    <!-- 商品弹窗 -->
    <MarketingGoods :type="2" ref="goods" :show="dialogVisible"></MarketingGoods>
    <!-- 分类弹窗 -->
    <CategoryList ref="category" :show="dialogVisible2"></CategoryList>
    <!-- 折扣商品弹窗 -->
    <DiscGoods ref="disc" :show="dialogVisible4"></DiscGoods>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import LeftComponent from "@/components/shopDecoration/LeftComponent.vue";
import RightForm from "@/components/shopDecoration/RightForm.vue";
import MarketingGoods from "@/components/goods/MarketingGoods.vue";

import CategoryList from "@/components/goods/CategoryList.vue";

import DiscGoods from "@/components/shopDecoration/goodsAndClass/DiscGoods.vue";

// 店铺装饰组件
// 媒体相关组件
import ShopCover from "@/components/shopDecoration/ShopCover";
import BottomGoods from "@/components/shopDecoration/BottomGoods";
import Slide from "@/components/shopDecoration/Slide";
import Multigraph from "@/components/shopDecoration/Multigraph";
import Videos from "@/components/shopDecoration/Videos";
import SlideText from "@/components/shopDecoration/SlideText";
import Texts from "@/components/shopDecoration/Texts";

// 商品相关组件
import GoodsBright from "@/components/shopDecoration/goodsAndClass/GoodsBright";

// 商品亮点
import CommodityHighlights from "@/components/shopDecoration/goodsAndClass/CommodityHighlights";
// 新商品
import NewGoods from "@/components/shopDecoration/goodsAndClass/NewGoods";

import GoodsCategory from "@/components/shopDecoration/goodsAndClass/GoodsCategory";
import ImgCategory from "@/components/shopDecoration/goodsAndClass/ImgCategory";
import HotSales from "@/components/shopDecoration/goodsAndClass/HotSales";

// 接口
import {
  decoration,
  getDecoration,
  subDecoration,
  shopInfo,
} from "@/api/shop.js";
export default {
  components: {
    Draggable,
    LeftComponent,
    ShopCover,
    Videos,
    SlideText,
    RightForm,
    MarketingGoods,
    BottomGoods,
    GoodsBright,
    ImgCategory,
    CategoryList,
    HotSales,
    DiscGoods,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogTableVisible3: false,
      dialogVisible4: false,
      zheDImg: require("@/assets/img/admin/zhedie.png"),
      leftFoldShow: false,
      isPush: false,
      value: 0,
      typeList: [
        {
          title: "媒体组件及文字",
          show: 1,
          data: {
            slide: {
              name: "滑动式图片",
              desc: "您可以使用轮播图展示促销商品、热销商品和商店分类。",
              max: 10,
              num: 0,
              key: "slide",
              com: Slide,
              data: {
                id: 0,
                block_layout: 1,
                block_module_type: "slide",
                block_text: "",
                block_content: [
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    goods_title: "",
                  },
                ],
              },
            },
            multigraph: {
              name: "多张图片",
              desc: "需要更多空间来展示您的商品？在此处添加2张图片，并把他们链接至商品详情页面或店内分类。",
              max: 10,
              num: 0,
              key: "multigraph",
              com: Multigraph,
              data: {
                id: 0,
                block_layout: 1,
                block_module_type: "multigraph",
                block_text: "",
                block_content: [
                  {
                    file_id: 0,
                    image: "",
                    goods_id: [],
                    goods_title: [],
                  },
                  {
                    file_id: 0,
                    image: "",
                    goods_id: [],
                    goods_title: [],
                  },
                ],
              }, // 数据
            },
            video: {
              name: "视频",
              desc: "使用商品或品牌的视频吸引更多买家。",
              max: 3,
              num: 0,
              key: "video",
              com: Videos,
              data: {
                id: 0,
                block_layout: "",
                block_module_type: "video",
                block_text: "",
                block_content: {
                  file_id: 0,
                  video: "",
                  video_cover: "",
                  video_img: "",
                  video_short: "",
                },
              }, // 数据
            },
            slide_text: {
              name: "滑动式图片和商店介绍文本",
              desc: "您可以使用轮播图片和商店介绍文本来展示您的品牌，商品，以及促销活动。",
              max: 1,
              num: 0,
              key: "slide_text",
              com: SlideText,
              data: {
                id: 0,
                block_layout: 1,
                block_module_type: "slide_text",
                block_text: "",
                block_content: [
                  {
                    file_id: 0,
                    image: "",
                    goods_id: [],
                    goods_title: [],
                  },
                ],
              }, // 数据
            },
            text: {
              name: "文字",
              desc: "用文字分享您的店铺或品牌故事。",
              max: 10,
              num: 0,
              key: "text",
              com: Texts,
              data: {
                id: 0,
                block_layout: "",
                block_module_type: "text",
                block_text: "",
                block_content: "",
              },
            },
          },
        },
        {
          title: "商品及分类",
          show: 1,
          data: {
            goods_light: {
              name: "商品亮点",
              desc: "在此展示您最新或最畅销的商品，以带动流量及销量。",
              max: 10,
              num: 0,
              key: "goods_light",
              com: CommodityHighlights,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_light",
                block_text: "",
                goodsList: [
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                ],
                block_content: [],
              },
            },
            goods_cate: {
              name: "商品-按分类",
              desc: "在此处展示您的热门商品。我们推荐您从您最热门的分类开始。",
              max: 10,
              num: 0,
              key: "goods_cate",
              com: GoodsCategory,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_cate",
                block_text: "",
                block_content: {
                  cate_id: 0,
                  cate_name: "",
                  cate_name_log: "",
                },
                goods_list: [
                  {
                    image: "",
                    goods_title: "",
                    goods_price: "",
                  },
                  {
                    image: "",
                    goods_title: "",
                    goods_price: "",
                  },
                  {
                    image: "",
                    goods_title: "",
                    goods_price: "",
                  },
                  {
                    image: "",
                    goods_title: "",
                    goods_price: "",
                  },
                  {
                    image: "",
                    goods_title: "",
                    goods_price: "",
                  },
                  {
                    image: "",
                    goods_title: "",
                    goods_price: "",
                  },
                ],
              },
            },
            goods_hot: {
              name: "热门商品",
              desc: "根据您选择的排序方式，在您的店铺首页展示商品。",
              max: 10,
              num: 0,
              key: "goods_hot",
              com: GoodsBright,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_hot",
                block_text: "标题",
                goodsList: [
                  {
                    image: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  // {
                  //   image: "",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                  // {
                  //   image: "",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                ],
                block_content: [],
              },
            },
            goods_new: {
              name: "新商品",
              desc: "在您的商店主页上自动展示新商品。",
              max: 1,
              num: 0,
              key: "goods_new",
              com: NewGoods,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_new",
                block_text: "新商品",
                goodsList: [
                  {
                    image: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                ],
                block_content: [],
              },
            },
            image_cate: {
              name: "图片分类列表",
              desc: "正在管理一个大型商店？在此处展示您的热门分类以帮助您的客户找到他们喜欢的商品。",
              max: 1,
              num: 0,
              key: "image_cate",
              com: ImgCategory,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "image_cate",
                block_text: "分类",
                block_content: [
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                ],
              },
            },
          },
        },
        {
          title: "促销",
          show: 1,
          data: {
            hot_promotion: {
              name: "热门促销",
              desc: "在商店主页针对不同的买家展示不同的折扣商品。",
              max: 1,
              num: 0,
              key: "hot_promotion",
              com: HotSales,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "hot_promotion",
                block_text: "热门促销",
                block_content: [],
                goodsList: [
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                ],
              },
            },
          },
        },
        {
          title: "隐藏",
          show: 1,
          data: {
            shop_cover: {
              name: "店铺分娩",
              max: 1,
              num: 1,
              key: "shop_cover",
              com: ShopCover,
            },
          },
        },
      ],
      viewList: [
        {
          title: "店铺封面",
          index: 3,
          type: "shop_cover",
          data: {
            block_layout: 1,
            block_module_type: "shop_cover",
            block_text: "",
            block_content: [
              {
                file_id: 0,
                image: "",
                goods_id: [],
                goods_title: [],
              },
            ],
          },
        },
        // {
        //   title: "滑动式",
        //   index: 0,
        //   type: "slide",
        //   data: {
        //     block_layout: 1,
        //     block_module_type: "slide",
        //     block_text: "",
        //     block_content: [
        //       {
        //         file_id: 0,
        //         image: "",
        //         goods_id: [],
        //         goods_title: [],
        //       },
        //     ],
        //   },
        // },
        // {
        //   title: "多张图片",
        //   index: 0,
        //   type: "multigraph",
        //   data: {
        //     block_layout: 1,
        //     block_module_type: "multigraph",
        //     block_text: "",
        //     block_content: [
        //       {
        //         file_id: 0,
        //         image: "",
        //         goods_id: [],
        //         goods_title: [],
        //       },
        //       {
        //         file_id: 0,
        //         image: "",
        //         goods_id: [],
        //         goods_title: [],
        //       },
        //       {
        //         file_id: 0,
        //         image: "",
        //         goods_id: [],
        //         goods_title: [],
        //       },
        //     ],
        //   }, // 数据
        // },
      ],

      info: [],
      categoryDataa: [],
      categoryDatab: [],
      addTime: 0,
      onlineTime: 0,
      viewType: "", //组件类型
      index: "", //组件下标
      listIndex: 0, //组件组下标
      activeIndex: "", //选中元素下标
      viewTitle: "", //拖拽组件标题
      viewData: "",
      xz: require("@/assets/img/app/xz.png"),
      s: require("@/assets/img/app/s.png"),
      ylShow: false,
      component_id: [],
      fbTime: "",
    };
  },
  created() {
    this.getShopInfo();
    this.getInfo();
  },
  mounted() {
    this.$store.state.globalLoadShow = true;
  },
  methods: {
    // 获取店铺装修信息
    getInfo(type) {
      getDecoration().then((res) => {
        if (res.code == 1) {
          let arr = [];
          res.data.forEach((item) => {
            let index = 0;
            let title = "";
            item.block_layout = Number(item.block_layout);
            if (item.block_content.constructor === Array) {
              if (
                item.block_module_type == "goods_light" ||
                item.block_module_type == "goods_hot" ||
                item.block_module_type == "goods_new" ||
                item.block_module_type == "image_cate" ||
                item.block_module_type == "hot_promotion"
              ) {
                item.goodsList = [];
              }

              item.block_content.forEach((val) => {
                val.images = val.image;
                val.image = val.short_image;
                val.goods_images = val.goods_image;
                val.category_name_z = val.category_name;

                if (
                  item.block_module_type == "goods_light" ||
                  item.block_module_type == "goods_hot" ||
                  item.block_module_type == "goods_new" ||
                  item.block_module_type == "image_cate" ||
                  item.block_module_type == "hot_promotion"
                ) {
    
                  val.title = val.goods_title;
                  item.goodsList.push(val);
 

                } else {
                  val.goods_id = val.goods_id.split(",");
                  val.goods_title = val.goods_title
                    ? val.goods_title.split(",")
                    : val.goods_title || "";
                }
              });
              if (
                item.block_module_type == "goods_light" ||
                item.block_module_type == "goods_hot" ||
                item.block_module_type == "goods_new"
              ) {
                if (item.goodsList.length < 4) {
                  for (let i = 0; i <= 6 - item.goodsList.length; i++) {
                    // item.goodsList.push({
                    //   file_id: 0,
                    //   image: "",
                    //   images: "",
                    //   goods_id: "",
                    //   title: "",
                    //   price: 0,
                    // });
                  }
                }
              } else if (item.block_module_type == "hot_promotion") {
                // for (let i = 0; i < 8 - item.goodsList.length; i++) {
                //   item.goodsList.push({
                //     discount_id: 0,
                //     goods_id: "",
                //     goods_title: "",
                //     goods_image: "",
                //     goods_images: "",
                //     goods_price: "",
                //     discount_price: "",
                //     discount_ration: "",
                //   });
                // }
              }
            }

            if (item.block_content.constructor === Object) {
              let url = item.block_content.video;
              item.block_content.video = item.block_content.video_short;
              item.block_content.video_short = url;

              item.block_content.video_img = item.block_content.video_cover;
              item.block_content.video_cover =
                item.block_content.video_cover_short;

              if (item.block_module_type == "goods_cate") {
                item.goods_list =item.block_content.goods_list;
              }
            }

            for (let i = 0; i < 3; i++) {
              if (this.typeList[i].data[item.block_module_type]) {
                index = i;
                title = this.typeList[i].data[item.block_module_type].name;
              }
            }

            arr.push({
              title: title,
              index: index,
              type: item.block_module_type,
              data: item,
            });
          });
          // 处理已添加组件数量
          arr.forEach((item) => {
            if (this.typeList[item.index].data[item.type]) {
              if (!type) {
                this.typeList[item.index].data[item.type].num += 1;
              }
            }
          });

          this.viewList.push.apply(this.viewList, arr);
        }
      });
    },
    getShopInfo() {
      shopInfo().then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.fbTime = res.data.last_time;
        }
      });
    },
    initData() {},
    //   右侧组件框显示隐藏
    handleLeftFold() {
      this.leftFoldShow = this.leftFoldShow ? false : true;
      this.value = this.leftFoldShow ? -288 : 0;
    },
    // 行组件折叠
    handleTemplShow(index) {
      this.typeList[index].show = this.typeList[index].show ? 0 : 1;
    },
    drog(e) {
      if (!this.viewType) return; // 内容拖拽

      e.preventDefault();
      e.stopPropagation();
      this.dragEnd();
    },
    // 拖拽进入可添加取域
    dragover(e) {
      if (!this.viewType) return;
      e.preventDefault();
      e.stopPropagation();
      let name = e.target.className;
      // let name = className == "in-card" ? "item" : "in-card";
      e.dataTransfer.dropEffect = "default";
      const defaultData = {
        type: this.viewType, // 组件类型
        status: 2, // 默认状态
        data: this.viewData, // 数据
        index: this.listIndex,
        title: this.viewTitle,
      };

      if (name == "in-card") {
        if (!this.isPush) {
          this.index = this.viewList.length;
          this.isPush = true;
          this.viewList.push(defaultData);
          this.typeList[this.listIndex].data[this.viewType].num += 1;
          this.addComNum();
        }
      } else if (name == "item") {
        let target = e.target;
        let [y, h, curIndex] = [
          e.offsetY, //高度
          target.offsetHeight, //元素高度
          target.dataset.index, // 元素下标
        ];
        let direction = y < h / 2;
        if (e.target.dataset.index == 0) {
          if (direction) {
            // 判断是否是店铺封面
            return false;
          }
        }
        if (!this.isPush) {
          if (direction) {
            if (curIndex == 0) {
              this.viewList.unshift(defaultData);
            } else {
              this.viewList.splice(curIndex, 0, defaultData);
            }
          } else {
            curIndex = +curIndex + 1;
            this.viewList.splice(curIndex, 0, defaultData);
          }
          this.typeList[this.listIndex].data[this.viewType].num += 1;
          this.addComNum();
        } else {
          let i = "";
          let result = "";
          if (direction) {
            i = curIndex == 0 ? 0 : curIndex - 1;
            result = this.viewList[i]["status"] == 2;
          } else {
            i = +curIndex + 1;
            result =
              this.viewList.length > i && this.viewList[i]["status"] == 2;
          }
          if (result) return;
          const temp = this.viewList.splice(this.index, 1);
          this.viewList.splice(curIndex, 0, temp[0]);
        }
        this.index = curIndex;
        this.isPush = true;
      } else {
        return false;
      }
    },
    addComNum() {
      // subDecorateComponent({
      //   component_name:this.
      // }).then((res) => {
      //   if (res.code == 1) {
      //   }
      // });
    },
    // 拖拽开始
    dragStart(e) {
      this.listIndex = e.target.dataset.index;
      this.viewType = e.target.dataset.type;
      this.viewTitle = e.target.dataset.title;
      this.viewData = JSON.parse(e.target.dataset.data);
    },
    // 拖拽结束
    dragEnd(e) {
      // if (!e) {
      //   this.typeList[this.listIndex].data[this.viewType].num += 1;
      // }
      if (this.index != "") {
        this.$delete(this.viewList[this.index], "status");
        this.isPush = false;
        this.viewType = null;
        this.listIndex = "";
        this.viewTitle = "";
        this.viewData = "";
      }
    },
    selectType(e, index) {
      if (this.activeIndex !== index) {
        let box = document.getElementsByClassName("editDecorate");
        let juli =
          e.target.className != "item"
            ? e.target.parentElement.offsetTop / 2
            : e.target.offsetTop / 2;

        let time = setInterval(function () {
          if (
            box[0].scrollTop <= juli &&
            Math.ceil(box[0].scrollTop + box[0].clientHeight) !=
              box[0].scrollHeight
          ) {
            box[0].scrollTop += 10;
          } else {
            clearInterval(time);
          }
        }, 10);

        this.activeIndex = index;
      }
    },
    onMove(e) {
      if (e.relatedContext.element.id == 1) return false;
    },
    // 组件向上
    handleUpWard(index) {
      if (index === 0 || index == 1) return;
      this.activeIndex = index - 1;
      this.viewList.splice(
        index - 1,
        1,
        ...this.viewList.splice(index, 1, this.viewList[index - 1])
      );
    },
    // 组件向下
    handleDown(index) {
      if (index === this.viewList.length - 1 || index == 0) return;
      this.activeIndex = index + 1;
      this.viewList.splice(
        index,
        1,
        ...this.viewList.splice(index + 1, 1, this.viewList[index])
      );
    },
    // 删除组件
    hadleItem(val, index) {
      if (index === 0) return;

      this.component_id.push(val.data.id);
      this.typeList[val.index].data[val.type].num -= 1;
      this.viewList.splice(index, 1);
    },
    // 打开商品选择弹窗
    openPop() {
      // 重置商品选择框
      // this.$refs.goods.$data.checkedCities = [];
      // this.$refs.goods.$data.isIndeterminate = false;
      // this.$refs.goods.$data.alls = false;
      this.dialogVisible = true;
    },
    // 打开折扣商品弹窗
    openPopDisc() {
      this.dialogVisible4 = true;
    },
    // 商品选择确认
    handleAndGoods(data) {
      if (this.viewList[this.activeIndex].type == "goods_light") {
        if (data.length > 20) {
          this.$message.warning("最多选择20个商品");
          return false;
        }
        let arr = [];
        data.forEach((item) => {
          arr.push({
            file_id: item.attachment_id,
            image: item.image_short,
            images: item.image,
            goods_id: item.id,
            title: item.title,
            price: item.price,
          });
        });
        this.viewList[this.activeIndex].data.block_content = arr;
        let arr1 = JSON.stringify(arr);
        arr1 = JSON.parse(arr1);
        if (data.length < 4) {
          for (let i = 0; i < 4 - data.length; i++) {
            arr1.push({
              file_id: 0,
              image: "",
              images: "",
              goods_id: "",
              title: "",
              price: 0,
            });
          }
        }
        this.viewList[this.activeIndex].data.goodsList = arr1;
        this.dialogVisible = false;
        return false;
      }
      if (
        this.viewList[this.activeIndex].type == "slide" ||
        this.viewList[this.activeIndex].type == "slide_text"
      ) {
        if (data.length > 1) {
          this.$message.warning("最多选择一个商品");
          return false;
        }
      }

      let index = sessionStorage.FORMACTIVEINDEX;
      let ids = [],
        titles = [];
      ids.push(data[0].id);
      titles.push(data[0].title);
      this.viewList[this.activeIndex].data.block_content[index].goods_id = ids;

      this.viewList[this.activeIndex].data.block_content[index].goods_title =
        titles;
      this.dialogVisible = false;
    },
    // 分类选择确认
    handleAndClass(data) {
      if (this.viewList[this.activeIndex].type == "image_cate") {
        let index = sessionStorage.IMAGECATEINDEX;
        this.viewList[this.activeIndex].data.block_content[index].category_id =
          data.category_id;
        this.viewList[this.activeIndex].data.block_content[
          index
        ].category_name = data.name;
        this.viewList[this.activeIndex].data.block_content[
          index
        ].category_name_z = data.name;
      } else {
        this.viewList[this.activeIndex].data.block_content.cate_id =
          data.category_id;
        this.viewList[this.activeIndex].data.block_content.cate_name =
          data.name;
        this.viewList[this.activeIndex].data.block_text = data.name;
      }

      this.dialogVisible2 = false;
    },
    // 热门促销商品选择
    handleAddDiscGoods(arr) {
      if (arr.length > 20) {
        this.$message.warning("最多选择6个商品");
        return false;
      }
      let arr1 = [];
      arr.forEach((item) => {
        arr1.push({
          discount_id: item.discount_id,
          goods_id: item.goods_id,
          goods_title: item.goods_title,
          goods_image: item.goods_image,
          goods_images: item.goods_image,
          goods_price: item.goods_min_price,
          discount_price: item.goods_min_discount_price,
          discount_ration: item.goods_discount,
        });
      });
      this.viewList[this.activeIndex].data.block_content = arr1;
      let arr2 = JSON.stringify(arr1);
      arr2 = JSON.parse(arr2);
      if (arr2.length < 7) {
        for (let i = 0; 1 < 7 - arr2.length; i++) {
          arr2.push({
            discount_id: 0,
            goods_id: "",
            goods_title: "",
            goods_image: "",
            goods_images: "",
            goods_price: "",
            discount_price: "",
            discount_ration: "",
          });
        }
        this.viewList[this.activeIndex].data.goodsList = arr2;
      }

      this.dialogVisible4 = false;
    },
    // 组件提交
    submit(index) {
      let data = this.dataGl(index);

      decoration({
        ...data,
      }).then((res) => {
        if (res.code == 1) {
          this.viewList[index].data.id = res.data;
          this.$message.success("储存成功");
        }
      });
    },
    // 数据过滤
    dataGl(index) {
      this.viewList[index].data.block_sort = index;
      this.viewList[index].data.index = this.viewList[index].index;
      let data = JSON.stringify(this.viewList[index].data);
      data = JSON.parse(data);
      if (data.block_content.constructor === Array) {
        data.block_content.forEach((item) => {
          if (this.viewList[index].type != "hot_promotion") {
            delete item.goods_title;
          }

          delete item.images;
          delete item.short_image;
          delete item.title;
          delete item.category_name_log;
          delete item.category_name_z;
          delete item.goods_images;
          if (this.viewList[index].type != "image_cate") {
            item.goods_id =
              typeof item.goods_id === "number" ||
              typeof item.goods_id === "string"
                ? item.goods_id
                : item.goods_id.join(",");
          }
        });
      }
      if (data.block_content.constructor === Object) {
        delete data.block_content.video_img;
        delete data.block_content.cate_name_log;
      }
      return data;
    },
    // 整体提交/保存
    handleAllSave(type) {
      let _this = this;
      function operations() {
        let arr = [];
        _this.viewList.forEach((item, index) => {
          if (index !== 0) {
            arr.push(_this.dataGl(index));
          }
        });
        subDecoration({
          status: type,
          content: arr,
          component_id: _this.component_id.join(","),
        }).then((res) => {
          if (res.code == 1) {
            _this.viewList = [
              {
                title: "店铺封面",
                index: 3,
                type: "shop_cover",
                data: {
                  block_layout: 1,
                  block_module_type: "shop_cover",
                  block_text: "",
                  block_content: [
                    {
                      file_id: 0,
                      image: "",
                      goods_id: [],
                      goods_title: [],
                    },
                  ],
                },
              },
            ];
            if (type == 0) {
              _this.$message.success("储存成功");
            } else {
              _this.$message.success("发布成功");

              // _this.$router.go(-1);
            }
            _this.getInfo(true);
          }
        });
      }
      if (type == 0) {
        operations();
      } else {
        this.$confirm(
          "店铺装饰目前已停用。如果您想发布您的草稿，您的店铺装饰将显示为主页。",
          "发布",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          operations();
        });
      }
    },

    handleClose() {
      if (this.viewList[this.activeIndex].type == "image_cate") {
        let index = sessionStorage.IMAGECATEINDEX;
        this.viewList[this.activeIndex].data.block_content[
          index
        ].category_name_z =
          this.viewList[this.activeIndex].data.block_content[
            index
          ].category_name_log;
      } else {
        this.viewList[this.activeIndex].data.block_content.cate_name =
          this.viewList[this.activeIndex].data.block_content.cate_name_log;
      }
      this.dialogVisible2 = false;
    },
    handleOpenClass() {
      this.$refs.category.value = "";
      this.dialogVisible2 = true;
    },
    // 打开预览
    handleLook() {
      this.dialogTableVisible3 = true;
      // this.ylShow = true;
      // let _this = this;
      // setTimeout(function () {
      //   _this.ylShow = false;
      // }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.editDecorate {
  overflow: scroll;
  height: 100%;
  user-select: none;
  .top {
    height: 56px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 24px;
    background-color: inherit;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    background: #fff;
    position: fixed;
  }
  .left {
    width: 288px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56px;
    bottom: 0;
    transition: all 0.4s;

    .fold-box {
      position: absolute;
      right: -24px;
      width: 24px;
      height: 56px;
      top: 40%;
      text-align: center;
      cursor: pointer;
      font-size: 20px;

      img {
        width: 100%;
        height: 100%;
      }
      .icons {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        line-height: 56px;
      }
    }

    .left-templ {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 16px;
      }
      .left-templ_cell {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .left-templ_item {
          width: 120px;
          margin-bottom: 20px;
          position: relative;
          .zbc {
            position: absolute;
            top: 0;
            width: 120px;
            height: 120px;
            background: #eee;
            opacity: 0.8;
            cursor: not-allowed;
            z-index: 1;
          }
          .img {
            width: 120px;
            height: 120px;
            background: #fafafa;
            cursor: move;
            border: 1px solid transparent;
            transform: translateZ(0);
          }
          .img:hover {
            border-color: #2673dd;
          }
        }
      }
    }
  }
  .in {
    width: 1240px;
    // width: 620px;
    margin: 0 auto;
    padding-top: 20px;
    height: 100px;

    .in-card {
      width: 1240px;
      // height: 200%;
      min-height: 1000px;
      transform: scale(0.5);
      transform-origin: top;
      padding-bottom: 800px;
      border-left: 2px solid #eee;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);

      .left-tag {
        transform: translateX(-110%) scale(2);
        font-size: 12px;
        text-align: center;
        padding: 8px 8px;
        position: absolute;
        border-radius: 2px;
        top: 0;
        left: -2px;
        max-width: 100px;
        user-select: text;
        transform-origin: top right;
        cursor: pointer;
      }
      .right-tag {
        width: 30px;
        height: 90px;
        transform: translateX(130%) scale(2);
        font-size: 12px;
        text-align: center;
        padding: 8px 8px;
        position: absolute;
        border-radius: 2px;
        top: 0;
        right: -2px;
        max-width: 100px;
        user-select: text;
        transform-origin: top left;
        cursor: pointer;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 26px;
        }
      }
      .right-form {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(220%) scale(2);
        transform-origin: top right;
      }
    }
  }
  .view-pop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1111;
    padding: 40px;
    .view-box {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 4px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 20px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .view-in {
        width: 100%;
        height: 100%;
        overflow: scroll;
        border-radius: 4px;
        border: 1px solid #eee;
        margin: 20px 0;
        background: #f5f5f5;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .shopee-loading {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          overflow: hidden;
          background: #fff;
          z-index: 9999999999;
        }
        .xzimg {
          width: 200px;
          height: 200px;
          animation: fadenum 2.5s infinite;
          animation-timing-function: linear;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        @keyframes fadenum {
          100% {
            transform: rotate(360deg);
          }
        }
        .simg {
          width: 200px;
          height: 200px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .view-bottom {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
  }
}
.item {
  transition: all 0.3s;
  background: #f6f6f6;
  border: 2px solid transparent;
  position: relative;
  margin: 2px 0;
  &:hover {
    .el-icon-error {
      display: block;
    }
  }
  .asd {
    pointer-events: none;
  }
  .wait {
    background: #deedff;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-size: 22px;
    color: #2673dd;
    border: 2px dashed #2673dd;
    pointer-events: none;
  }
  .el-icon-error {
    position: absolute;
    right: -10px;
    top: -6px;
    color: red;
    font-size: 25px;
    cursor: pointer;
    display: none;
    z-index: 9999;
    border-radius: 50%;
    background: #fff;
  }
}
</style>