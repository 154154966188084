<template>
  <div class="upload-img-videos">
    <el-upload
      class="avatar-uploader"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :http-request="customizeUpload"
      :accept="accept"
      name="imagelist"
    >
      <div class="upload-box" v-if="imageUrl">
        <el-image :src="imageUrl" class="avatar" fit="contain"></el-image>
      </div>
      <div class="upload-box" v-else>
        <i
          style="font-size: 18px"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <div style="font-size: 12px">上传图片</div>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { getSAuth, getSchool } from "@/utils/auth.js";
import { uploadFiles } from "@/api/common.js";
import env from "@/settings/env.js";
export default {
  name: "UploadImgAndVideo2",
  props: ["imageUrl", "ids", "index"],
  data() {
    return {
      headers: {
        Authorization: getSAuth(),
        school: getSchool(),
      },
      // imgList: this.imageUrl,
      action: "",
      apiUrl: "",
      accept: ".jpg,.jpeg,.png,.gif",
    };
  },
  methods: {
    customizeUpload(data) {
      let _this = this;
      const { file } = data;
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/gif"
      ) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (reader.readyState == 2) {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
              if (this.width > 300 || this.height > 300) {
                _this.$message.error("图片尺寸超过最大上限");
              } else {
                let isLt2M = true;
                isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                  _this.$alert("最大文件大小必须小于2.0MB", "图片尺寸不正确", {
                    confirmButtonText: "确认",
                    callback: () => {},
                  });
                } else {
                  console.log("上传");
                  _this.submit(file);
                }
              }
            };
          }
        };
      } else {
        _this.$message.error("文件格式不正确");
      }
    },
    submit(file) {
      let formData = new FormData();
      formData.append("imagelist", file);
      uploadFiles(formData).then((res) => {
        if (res.code == 1) {
          this.$emit("uploadImg", { data: res.data, index: this.$props.index });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.upload-img-videos {
  .upload-box {
    width: 72px;
    height: 72px;
    border: 1px dashed #d8d8d8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #b7b7b7;
    border-radius: 4px;
    background: #fff;
  }
  .avatar {
    width: 72px;
    height: 72px;
  }
  .upload-box:hover {
    color: #ee4d2d;
    border-color: #ee4d2d;
  }
}
</style>


      // :on-success="handleAvatarSuccess"
      // :before-upload="beforeAvatarUpload"