<template>
  <el-dialog
    width="1000px"
    :before-close="handleClose"
    title="选择折扣商品"
    :visible.sync="show"
  >
    <div style="margin-top: -30px">
      <span style="margin: 10px 20px 10px">搜索</span>
      <el-input
        size="small"
        style="width: 400px"
        placeholder="请输入内容"
        v-model="value"
        class="input-with-select"
      >
        <el-select
          @change="handleChange"
          style="width: 100px"
          v-model="select"
          slot="prepend"
          placeholder="请选择"
        >
          <el-option label="商品名称" :value="1"></el-option>
          <!-- <el-option label="商品编号" :value="2"></el-option> -->
        </el-select>
      </el-input>

      <el-button
        style="margin-left: 20px"
        size="small"
        type="primary"
        @click="getList"
        >搜索</el-button
      >
      <el-button
        size="small"
        @click="
          select = 1;
          value = '';
          getList();
        "
        >重置</el-button
      >
    </div>
    <div style="border: 1px solid #eee; border-radius: 4px; margin-top: 20px">
      <el-table
        :header-cell-style="{
          background: '#f6f6f6',
        }"
        :data="gridData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column width="300" property="name" label="商品">
          <template slot-scope="scope">
            <div style="display: flex">
              <div style="margin-right: 10px">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.goods_image"
                  fit="fit"
                ></el-image>
              </div>
              <div>
                <div style="height: 40px" class="row-hidden">
                  {{ scope.row.goods_title }}
                </div>
                <!-- <div style="color: #ee4d2d; margin-top: 30px">
                  RM{{
                    scope.row.goods_min_price + "-" + scope.row.goods_max_price
                  }}
                </div> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" property="count" label="原价">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.goods_min_price === scope.row.goods_max_price
                  ? scope.row.goods_min_price
                  : scope.row.goods_min_price + "-" + scope.row.goods_max_price
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" property="address" label="折扣价格">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.goods_min_discount_price ==
                scope.row.goods_max_discount_price
                  ? scope.row.goods_min_discount_price
                  : scope.row.goods_min_discount_price +
                    "-" +
                    scope.row.goods_max_discount_price
              }}
            </div>
          </template></el-table-column
        >
        <el-table-column align="center" property="address" label="折扣">
          <template slot-scope="scope">
            <div>{{ scope.row.goods_discount * 0.1 }}折</div>
          </template></el-table-column
        >
      </el-table>
    </div>
    <div style="margin-top: 20px; display: flex; justify-content: right">
      <el-button @click="handleClose" style="min-width: 80px" size="medium"
        >取消</el-button
      >
      <el-button
        @click="handleClick"
        style="min-width: 80px"
        size="medium"
        type="primary"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { getDiscountGoodsList } from "@/api/shopGoods.js";
export default {
  name: "CategoryList",
  props: ["show"],
  data() {
    return {
      gridData: [],
      value: "",
      select: 1,
      multipleSelection: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      if (this.select == 1) {
        this.goods_name = this.value;
        this.goods_id = "";
      } else {
        this.goods_id = this.value;
        this.goods_name = "";
      }
      getDiscountGoodsList({
        goods_name: this.name,
        goods_id: this.value,
      }).then((res) => {
        if (res.code == 1) {
          this.gridData = res.data;
        }
      });
    },
    handleClose() {
      this.$parent.dialogVisible4 = false;
    },
    handleClick() {
      //   this.gridData.forEach((item) => {
      //     if (item.category_id === this.value) {
      //       this.$parent.handleAndClass(item);
      //     }
      //   });
      if (this.multipleSelection.length > 6) {
        this.$message.warning("选择的商品不能大于6个");
        return false;
      }
      this.$parent.handleAddDiscGoods(this.multipleSelection);
    },
    handleChange() {
      this.value = "";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
.info-btn {
  color: #2673dd;
  cursor: pointer;
}
.info-btn:hover {
  color: #0046ab;
}
</style>