<template>
  <div class="LeftComponent">
    <div v-if="keyName == 'slide'">
      <div class="slide-lz backC">
        <div class="slide-lz_in line">
          <svg
            fill="#b7b7b7"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
          >
            <path
              fill-rule="evenodd"
              d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'multigraph'">
      <div class="multigraph-lz">
        <div class="multigraph-lz_item backC" v-for="item in 2" :key="item">
          <div class="multigraph-lz_in line">
            <svg
              fill="#b7b7b7"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
            >
              <path
                fill-rule="evenodd"
                d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'video'">
      <div class="video-lz backC">
        <div class="video-lz_in line">
          <svg
            t="1669623715186"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3439"
            width="20"
            height="20"
          >
            <path
              d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m196.546667 500.493333l-266.666667 176A37.333333 37.333333 0 0 1 384 688V336.033333a37.333333 37.333333 0 0 1 57.893333-31.16l266.666667 176a37.333333 37.333333 0 0 1 0 62.32z"
              fill="#b7b7b7"
              p-id="3440"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'slide_text'">
      <div class="slide_text-lz backC">
        <svg
          fill="#b7b7b7"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
        >
          <path
            fill-rule="evenodd"
            d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
          ></path>
        </svg>
      </div>
      <span
        class="textC"
        style="position: absolute; right: 0; top: 40%; font-weight: 600"
        >Aa</span
      >
    </div>
    <div v-if="keyName == 'text'" style="width: 100%">
      <div class="text-lz backC">
        <div class="textC" style="font-weight: 600; padding-left: 10px">Aa</div>
        <div
          style="
            background: #fff;
            width: 94px;
            height: 6px;
            margin-left: 10px;
            margin-bottom: 6px;
            border-radius: 2px;
          "
        ></div>
        <div
          style="
            background: #fff;
            width: 64px;
            height: 6px;
            margin-left: 10px;
            border-radius: 2px;
          "
        ></div>
      </div>
    </div>

    <div v-if="keyName == 'goods_light'">
      <div
        style="
          position: absolute;
          bottom: 0;
          right: 0;
          background: #2673DD;
          color:#fff;
          font-size: 12px;
          padding: 2px 10px;
          border-radius: 10px 0 0 0;
        "
      >
        智能
      </div>
      <div class="goods_light-lz">
        <div v-for="item in 4" :key="item" style="">
          <div class="goods_light-lz_in backC">
            <svg
              fill="#b7b7b7"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
            >
              <path
                fill-rule="evenodd"
                d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              ></path>
            </svg>
          </div>
          <div class="heng backC"></div>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'goods_cate'">
            <div
        style="
          position: absolute;
          bottom: 0;
          right: 0;
          background: #2673DD;
          color:#fff;
          font-size: 12px;
          padding: 2px 10px;
          border-radius: 10px 0 0 0;
        "
      >
        智能
      </div>
      <div class="goods_cate-lz">
        <div
          style="
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 2px;
            overflow: hidden;
          "
        >
          <div v-for="item in 3" :key="item" style="margin-right: 2px">
            <div class="goods_cate-lz_in backC">
              <svg
                fill="#b7b7b7"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </div>
            <div class="heng backC"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'goods_hot'">
      <div class="goods_hot-lz">
        <div
          style="
            overflow: hidden;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 2px;
          "
        >
          <div v-for="item in 3" :key="item" style="margin-right: 2px">
            <div class="goods_hot-lz_in backC">
              <svg
                fill="#b7b7b7"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
              <svg
                style="position: absolute; left: 2px; top: 2px"
                t="1669626832369"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3476"
                width="16"
                height="16"
                fill="#b7b7b7"
              >
                <path
                  d="M905.7 216.3c0-30.2-1.2-60-3.7-89.3-0.7-8.5-6.8-15.7-15.1-17.8C770.4 80 644 64 512 64s-258.4 16-375 45.3c-8.3 2.1-14.4 9.2-15.1 17.8-2.4 29.3-3.7 59.1-3.7 89.3 0 344.9 161.3 637.6 385.3 741.8 5.3 2.5 11.5 2.5 16.9 0 224-104.2 385.3-397 385.3-741.9z"
                  p-id="3477"
                ></path>
              </svg>
              <span
                style="
                  position: absolute;
                  left: 6px;
                  top: -14px;
                  font-size: 8px;
                  color: #fff;
                "
                >{{ item }}</span
              >
            </div>
            <div class="heng backC"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'goods_new'">
      <div class="goods_new-lz">
        <div
          style="
            overflow: hidden;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 2px;
          "
        >
          <div v-for="item in 3" :key="item" style="margin-right: 2px">
            <div class="goods_new-lz_in backC">
              <svg
                fill="#b7b7b7"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill-rule="evenodd">
                  <path
                    d="M21 11v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-9h18zm-8.137 2H11.25l-.75 1.106h1.181V19h1.182v-6zM6.5 6v.5a2 2 0 1 0 4 0V6H14v.5a2 2 0 1 0 4 0V6h2a1 1 0 0 1 1 1v3H3V7a1 1 0 0 1 1-1h2.5z"
                  ></path>
                  <path
                    d="M8.5 3.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1zM16 3.5a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1z"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </svg>
            </div>
            <div class="heng backC"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'image_cate'">
      <div class="goods_cate-lz">
        <div
          style="
            overflow: hidden;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 2px;
          "
        >
          <div v-for="item in 3" :key="item" style="margin-right: 2px">
            <div class="goods_cate-lz_in backC">
              <svg
                fill="#b7b7b7"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </div>
            <div class="heng backC"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="keyName == 'hot_promotion'">
            <div
        style="
          position: absolute;
          bottom: 0;
          right: 0;
          background: #2673DD;
          color:#fff;
          font-size: 12px;
          padding: 2px 10px;
          border-radius: 10px 0 0 0;
        "
      >
        智能
      </div>
      <div class="hot_promotion-lz">
        <div
          style="
            overflow: hidden;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 2px;
          "
        >
          <div v-for="item in 3" :key="item" style="margin-right: 2px">
            <div class="hot_promotion-lz_in backC">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#b7b7b7"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.614 3.258l2.407 3.654a.75.75 0 0 1 .098.607l-3.654 13.635a.75.75 0 0 1-.919.53l-8.243-2.208a.75.75 0 0 1-.53-.919L7.426 4.922a.75.75 0 0 1 .389-.476l3.91-1.962a2.25 2.25 0 0 1 2.89.774zm3.57 5.42l1.92 9.874a.75.75 0 0 1-.593.88l-4.438.863 3.111-11.617zm-5.789 1.737h-.578l-3.088 5.666h.59l3.076-5.666zm.027 2.799c-.39 0-.725.138-1.001.413-.277.275-.415.61-.415 1.003 0 .391.138.725.415 1.002.276.276.61.415 1.001.415.39 0 .725-.139 1.001-.415.277-.277.415-.61.415-1.002 0-.39-.138-.724-.415-1.001a1.365 1.365 0 0 0-1.001-.415zm0 .81c.168 0 .31.06.428.178s.177.26.177.428c0 .168-.059.31-.177.429a.583.583 0 0 1-.428.177.583.583 0 0 1-.428-.177.583.583 0 0 1-.177-.429c0-.167.059-.31.177-.428a.583.583 0 0 1 .428-.177zm-3.7-3.582c-.392 0-.726.138-1.002.415-.277.277-.415.61-.415 1.001 0 .391.138.725.415 1.002.276.277.61.415 1.001.415.394 0 .728-.138 1.003-.415.276-.277.413-.61.413-1.002 0-.39-.138-.724-.415-1a1.365 1.365 0 0 0-1-.416zm0 .811c.167 0 .31.06.427.177.118.118.177.261.177.428 0 .168-.059.31-.177.429a.583.583 0 0 1-.428.177.583.583 0 0 1-.428-.177.583.583 0 0 1-.177-.429c0-.167.059-.31.177-.428a.583.583 0 0 1 .428-.177zm3.884-7.818a1.21 1.21 0 0 0-1.204 1.217c0 .671.539 1.216 1.204 1.216a1.21 1.21 0 0 0 1.204-1.216 1.21 1.21 0 0 0-1.204-1.217z"
                ></path>
              </svg>
              <svg
                style="position: absolute; right: 1px; top: 1px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#b7b7b7"
                width="14"
                height="14"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 1a1 1 0 0 1 1 1v12.5a1 1 0 0 1-1.479.878L7.5 13.185l-4.021 2.193A1 1 0 0 1 2 14.5V2a1 1 0 0 1 1-1h9zM8.849 4l-3.366 6.371.676.429 3.366-6.371L8.849 4zm.7 2.761c-.45 0-.805.162-1.063.487-.258.325-.387.8-.387 1.425 0 .638.128 1.12.383 1.448.255.328.611.491 1.067.491.457 0 .813-.163 1.068-.491.255-.328.383-.807.383-1.439 0-.625-.128-1.102-.383-1.43-.255-.327-.611-.49-1.068-.49zm0 .724c.201 0 .35.095.449.286.097.19.146.494.146.911 0 .43-.049.737-.146.925a.477.477 0 0 1-.449.282.477.477 0 0 1-.448-.282c-.098-.188-.146-.496-.146-.925 0-.417.048-.72.146-.911.098-.19.247-.286.448-.286zM5.451 4.16c-.457 0-.813.164-1.068.491C4.128 4.98 4 5.457 4 6.082c0 .631.128 1.111.383 1.439.255.327.611.491 1.068.491.456 0 .813-.164 1.071-.491.258-.328.387-.808.387-1.439 0-.625-.129-1.102-.387-1.43-.258-.327-.615-.491-1.071-.491zm0 .724c.2 0 .35.095.448.286.098.19.146.494.146.911 0 .429-.047.737-.142.925a.474.474 0 0 1-.452.281.468.468 0 0 1-.445-.281c-.095-.188-.142-.496-.142-.925 0-.423.047-.728.142-.916a.468.468 0 0 1 .445-.281z"
                ></path>
              </svg>
            </div>
            <div class="heng backC"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeftComponent",
  props: ["keyName"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.LeftComponent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .line {
    border: 1px dashed #b0b0b0;
  }
  .backC {
    background: #eee;
  }
  .textC {
    color: #b7b7b7;
  }
  // 滑动式
  .slide-lz {
    width: 118px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .slide-lz_in {
      width: 106px;
      height: 48px;

      text-align: center;
      line-height: 48px;
    }
  }
  // 多张图片

  .multigraph-lz {
    width: 118px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .multigraph-lz_item {
      width: 57px;
      height: 61px;

      display: flex;
      align-items: center;
      justify-content: center;
      .multigraph-lz_in {
        width: 45px;
        height: 49px;
        text-align: center;
        line-height: 49px;
      }
    }
  }
  // 视频
  .video-lz {
    width: 118px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .video-lz_in {
      width: 106px;
      height: 48px;
      text-align: center;
      line-height: 48px;
    }
  }

  // 滑动式图片和商店介绍文本
  .slide_text-lz {
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    display: inline-block;
  }
  // 文本
  .text-lz {
    padding-bottom: 6px;
  }
  // 商品亮点
  .goods_light-lz {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px;
    flex-wrap: wrap;
    .goods_light-lz_in {
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
    }
    .heng {
      width: 100%;
      height: 4px;

      margin: 2px 0;
    }
  }
  // 商品分类  and图片分类
  .goods_cate-lz {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;

    .goods_cate-lz_in {
      width: 44px;
      height: 46px;
      text-align: center;
      line-height: 46px;
    }
    .heng {
      width: 100%;
      height: 4px;

      margin: 2px 0;
    }
  }

  // 热门商品
  .goods_hot-lz {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;

    .goods_hot-lz_in {
      width: 44px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      position: relative;
    }
    .heng {
      width: 100%;
      height: 4px;

      margin: 2px 0;
    }
  }
  // 新商品
  .goods_new-lz {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;

    .goods_new-lz_in {
      width: 44px;
      height: 46px;
      text-align: center;
      line-height: 46px;
    }
    .heng {
      width: 100%;
      height: 4px;
      margin: 2px 0;
    }
  }

  // 促销商品
  .hot_promotion-lz {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;

    .hot_promotion-lz_in {
      width: 44px;
      height: 46px;
      text-align: center;
      line-height: 48px;
      position: relative;
    }
    .heng {
      width: 100%;
      height: 4px;

      margin: 2px 0;
    }
  }
}

.LeftComponent:hover .line {
  border-color: #2673dd;
}
.LeftComponent:hover svg {
  fill: #2673dd;
}
.LeftComponent:hover path {
  fill: #2673dd;
}
.LeftComponent:hover .backC {
  background: #e1eaf7;
}
.LeftComponent:hover .textC {
  color: #2673dd;
}
</style>