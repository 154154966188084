<template>
  <el-dialog title="选择商品" :visible.sync="show" width="1000px" style="margin-top: -50px" :before-close="handleClose">
    <div style="margin-top: -30px">
      <div style="border-bottom: 1px solid #eee; margin-bottom: 20px">
        <div style="
            width: 60px;
            text-align: center;
            line-height: 40px;
            color: #ee4d2d;
            border-bottom: 4px solid #ee4d2d;
          ">
          选择
        </div>
      </div>
      <div style="display: flex; align-items: center;margin:0 0 10px">
        <div style="width: 50px; margin-left: 50px">类别</div>
        <!-- <el-select
          size="medium"
          style="width: 200px"
          v-model="goodsForm.type"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <div @click="handlePop">
          <el-input style="width: 200px" size="medium" readonly v-model="classData" placeholder="请输入"></el-input>
        </div>
        <div style="width: 50px; margin-left: 40px">搜索</div>
        <el-input size="medium" style="width: 540px" placeholder="请输入" v-model="goodsForm.keyword"
          class="input-with-select">
          <el-select style="width: 120px" v-model="select" slot="prepend" placeholder="请选择">
            <el-option label="商品名称" value="1"></el-option>
            <!-- <el-option label="商品编号" value="2"></el-option> -->
          </el-select>
        </el-input>
      </div>
      <div style="display: flex; align-items: center; margin: 0 0 10px" v-if="type == 2">
        <div style="width: 50px; margin-left: 50px">价格</div>

        <div style="margin-top: 10px">
          <el-input style="width: 240px" size="medium" v-model="minPrice" placeholder="请输入">
            <template slot="prepend">RM</template></el-input>
          -
          <el-input style="width: 240px" size="medium" v-model="maxPrice" placeholder="请输入">
            <template slot="prepend">RM</template></el-input>
        </div>
      </div>
      <div>
        <el-button @click="getList" style="min-width: 70px" type="primary" size="small">搜索</el-button>
        <el-button style="min-width: 70px" @click="handleReset" size="small">重设</el-button>
      </div>

      <div>
        <div style="font-szie: 12px; line-height: 50px">
          找到的商品{{ listData.total }}
        </div>
        <div class="pop-list">
          <div class="pop-list_head">
            <div style="width: 3%; padding-left: 10px">
              <el-checkbox :indeterminate="isIndeterminate" v-model="alls" @change="all"
                :disabled="listData.list && listData.list.length == 0"></el-checkbox>
            </div>
            <div style="width: 32%">商品</div>
            <div style="width: 15%; display: flex; align-items: center">
              销售
              <span style="margin-left: 4px">
                <div @click="handleSort('sales', 1)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-bottom: 5px solid #999;
                    cursor: pointer;
                  "></div>
                <div @click="handleSort('sales', 2)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-top: 5px solid #999;
                    margin-top: 4px;
                    cursor: pointer;
                  "></div>
              </span>
            </div>
            <div style="width: 20%; display: flex; align-items: center">
              价格
              <span style="margin-left: 4px">
                <div @click="handleSort('price', 1)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-bottom: 5px solid #999;
                    cursor: pointer;
                  "></div>
                <div @click="handleSort('price', 2)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-top: 5px solid #999;
                    margin-top: 4px;
                    cursor: pointer;
                  "></div>
              </span>
            </div>
            <div style="width: 15%;display: flex; align-items: center">库存
              <span style="margin-left: 4px">
                <div @click="handleSort('stock', 1)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-bottom: 5px solid #999;
                    cursor: pointer;
                  "></div>
                <div @click="handleSort('stock', 2)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-top: 5px solid #999;
                    margin-top: 4px;
                    cursor: pointer;
                  "></div>
              </span>
            </div>
            <div style="width: 9%;display: flex; align-items: center">上架日期
              <span style="margin-left: 4px">
                <div @click="handleSort('added_time', 1)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-bottom: 5px solid #999;
                    cursor: pointer;
                  "></div>
                <div @click="handleSort('added_time', 2)" style="
                    width: 4px;
                    border: 4px solid transparent;
                    border-top: 5px solid #999;
                    margin-top: 4px;
                    cursor: pointer;
                  "></div>
              </span>
            </div>
          </div>
          <div v-if="listData.list && listData.list.length != 0" style="max-height: 380px; overflow: scroll">
            <el-checkbox-group v-model="checkedCities" @change="single">
              <div class="pop-list_content" v-for="item in listData.list" :key="item.id">
                <div style="width: 3%; padding-left: 10px">
                  <el-checkbox :label="item.id">&ensp;</el-checkbox>
                </div>
                <div style="width: 32%; display: flex">
                  <div style="margin-right: 10px">
                    <el-image style="width: 40px; height: 40px" :src="item.image" fit="fit"></el-image>
                  </div>
                  <div>
                    <div style="width: 240px; font-size: 14px" class="single-hidden">
                      {{ item.title }}
                    </div>
                    <div style="font-size: 12px; margin-top: 4px">
                      全球商品货号:{{ item.goods_sn }}
                    </div>
                  </div>
                </div>
                <div style="width: 15%">{{ item.sales }}</div>
                <div style="width: 20%">RM{{ item.price }}</div>
                <div style="width: 15%">{{ item.stock }}</div>
                <div style="width: 9%">{{ formatTimeA(item.added_time * 1000, 4) }}</div>
              </div>
            </el-checkbox-group>
          </div>
          <div v-else style="text-align: center; line-height: 90px; color: #b0b0b0">
            未找到
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <span style="font-size: 12px; margin-right: 30px; color: #bfbfbf">已选
        <span style="color: #000">{{ checkedCities.length }}</span>
        个商品</span>
      <el-button size="medium" @click="handleClose">取 消</el-button>
      <el-button size="medium" type="primary" @click="handleGoods">确 认</el-button>
    </span>

    <el-dialog title="选择分类" :visible.sync="dialogVisible1" width="1200px" :before-close="handleClose1" append-to-body>
      <div class="add-pops">
        <el-input style="width: 400px" v-model="categoryName" size="medium" placeholder="类目名称"
          prefix-icon="el-icon-search" clearable @clear="getCategory" @keydown.native="searchCategory()"></el-input>
        <div>
          <el-cascader-panel style="
              background: #fff;
              margin-top: 20px;
              min-height: 300px;
              max-height: 400px;
            " v-model="categoryData" @change="change" :options="options" :props="configuration"></el-cascader-panel>
        </div>
        <div style="font-size: 14px; margin-top: 20px">
          正在选择的：<span style="color: #999999">{{
            areChoosing ? areChoosing : "没有选择任何类别"
          }}</span>
        </div>
        <div style="display: flex; justify-content: right">
          <el-button plain @click="handleClose1">取消</el-button>
          <el-button type="primary" @click="handleCategory">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { goodsList } from "@/api/shopGoods.js";
import { categoryInfo } from "@/api/common.js";
export default {
  props: ["show", "type"],
  data() {
    return {
      options: "",
      select: "1",
      url: "",
      dialogVisible1: false,
      goodsForm: {
        type: "",
        keyword: "",
      },
      listData: "",

      categoryName: "",
      categoryData: [],
      isIndeterminate: false,
      alls: false,
      checkedCities: [],
      configuration: {
        disabled: false,
        value: "category_id",
        label: "name",
        children: "list",
      },
      areChoosing: false,
      classData: "",
      minPrice: "",
      maxPrice: "",
      field: "",
      order: "",
    };
  },
  created() {
    this.getCategory();
    this.getList();
  },
  mounted() { },
  methods: {
    getList() {
      let price = "";
      if (this.minPrice != "") {
        price = this.minPrice + "-" + this.maxPrice;
      }

      this.minPrice;
      goodsList({
        title: this.select == 1 ? this.goodsForm.keyword : "",
        goods_sn: this.select == 2 ? this.goodsForm.keyword : "",
        specification_sn: "",
        specification: "",
        category_path_id: this.categoryData[this.categoryData.length - 1],
        goods_num: "",
        sales: "",
        state: "1",
        field: this.field,
        order: this.order,
        price: price,
        page: 1,
        page_size: 10000,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    getCategory() {
      categoryInfo({ keyword: this.categoryName }).then((res) => {
        if (res.code == 1) {
          this.options = res.data;
        }
      });
    },
    // 搜索类目
    searchCategory(e) {
      let evt = window.event || e;
      if (evt.keyCode == 13) {
        this.categoryData = "";
        this.areChoosing = false;
        this.getCategory();
      }
    },
    // 全选数据变化
    change(value) {
      let data = "";
      if (value[0]) {
        this.options.forEach((item) => {
          if (item.category_id == value[0]) {
            this.areChoosing = item.name;
            data = item;
          }
        });
      }
      if (value[1]) {
        data.list.forEach((item) => {
          if (item.category_id == value[1]) {
            this.areChoosing = this.areChoosing + "-" + item.name;
            data = item;
          }
        });
      }
      if (value[2]) {
        data.list.forEach((item) => {
          if (item.category_id == value[2]) {
            this.areChoosing = this.areChoosing + "-" + item.name;
          }
        });
      }
    },
    handleClose() {
      this.$parent.dialogVisible = false;
    },

    // 关闭类目弹窗
    handleClose1() {
      if (!this.areChoosing) {
        this.areChoosing = "";
        this.classData = "";
        this.categoryData = [];
      }

      this.categoryName = "";
      this.dialogVisible1 = false;
    },
    // 全选
    all(val) {
      let arr = [];
      if (val) {
        this.listData.list.forEach((item) => {
          arr.push(item.id);
        });
      }
      this.checkedCities = arr;
      this.isIndeterminate = false;
    },
    // 全选状态
    single(value) {
      let checkedCount = value.length;
      this.alls = checkedCount == this.listData.list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listData.list.length;
    },
    // 类目选择确认
    handleCategory() {
      let data = "";
      if (this.categoryData.length == 0) {
        this.$message.error("您未选择任何分类");
        return false;
      }
      if (this.categoryData[0]) {
        this.options.forEach((item) => {
          if (item.category_id == this.categoryData[0]) {
            this.classData = item.name;
            data = item;
          }
        });
      }
      if (this.categoryData[1]) {
        data.list.forEach((item) => {
          if (item.category_id == this.categoryData[1]) {
            this.classData = this.classData + "-" + item.name;
            data = item;
          }
        });
      }
      if (this.categoryData[2]) {
        data.list.forEach((item) => {
          if (item.category_id == this.categoryData[2]) {
            this.classData = this.classData + "-" + item.name;
          }
        });
      }
      this.dialogVisible1 = false;
    },
    // 重设
    handleReset() {
      this.areChoosing = "";
      this.classData = "";
      this.categoryData = [];
      this.categoryName = "";
      this.goodsForm.keyword = "";
      this.select = "1";
      this.minPrice = "";
      this.maxPrice = "";
      this.field = "";
      this.order = "";
      this.getList();
    },
    // 确认选择好的商品
    handleGoods() {
      let arr = [];
      this.listData.list.forEach((item) => {
        this.checkedCities.forEach((val) => {
          if (item.id == val) {
            arr.push(item);
          }
        });
      });
      this.$parent.handleAndGoods(arr);
    },
    handlePop() {
      this.dialogVisible1 = true;
      this.categoryName = "";
      this.getCategory();
    },
    // 列表排序
    handleSort(field, order) {
      this.field = field;
      this.order = order;
      this.getList();
    },
  },
  watch: {
    checkedCities: function (newData) {
      if (newData.length == 0) {
        this.isIndeterminate = false;
        this.alls = false;
      } else if (newData.length === this.listData.list.length) {
        this.alls = true;
      } else {
        this.isIndeterminate = true;
      }
    },
  },
};
</script>
<style lang="less" >
.pop-list {
  font-size: 14px;
  border: 1px solid #eee;
  border-radius: 4px;

  .pop-list_head {
    display: flex;
    background: #f6f6f6;
    padding: 10px;
    justify-content: space-between;
  }

  .pop-list_content {
    display: flex;
    padding: 10px;
    font-size: 12px;
    justify-content: space-between;
  }
}

.add-pops {
  width: 100%;
  padding: 30px;
  background: #fafafa;
  margin-top: -20px;

  .el-input__inner {
    border-radius: 30px;
  }

  .el-cascader-menu {
    min-width: 30%;
  }

  .el-cascader-menu__wrap {
    height: 106%;
  }
}
</style>