<template>
  <div class="rightForm" @click.stop="asd">
    <!-- 滑动式图片 -->
    <div v-if="type == 'slide' || type == 'slide_text'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">
          滑动式图片<span v-if="type == 'slide_text'">和商店介绍文本</span>
        </div>
        <div class="desc1" style="padding: 10px 0 0" v-if="type == 'slide'">
          您可以使用轮播图展示促销商品、热销商品和分类。
        </div>
        <div v-else class="desc1" style="padding: 10px 0 0">
          您可以使用轮播图片和商店介绍文本来展示您的品牌，商品，以及促销活动。
        </div>
      </div>
      <div class="conter">
        <span v-if="type == 'slide'">
          <div class="desc" style="padding: 10px 0 0">选中滑动式图片的比例</div>
          <div class="desc1" style="padding: 10px 0 0">
            您可自定义滑动式图片的比例。
          </div>
          <div style="display: flex; justify-content: center">
            <el-radio-group
              v-model="data.block_layout"
              style="margin-top: 30px"
            >
              <el-radio-button :label="1">2:1</el-radio-button>
              <el-radio-button :label="2">16:9</el-radio-button>
              <el-radio-button :label="3">1:1</el-radio-button>
              <el-radio-button :label="4">自由</el-radio-button>
            </el-radio-group>
          </div>
        </span>
        <div class="desc1">
          <span class="desc" style="margin: 20px 0 0; display: block"
            >上传图片</span
          >
          <br />
          图片:
          <br />
          · 图片大小不得超过2MB; 分辨率上限为2000 * 2000px
          <br />
          · 图片格式：JPG, JPEG, PNG, GIF
        </div>
        <div class="bj-box" v-for="(item, i) in data.block_content" :key="i">
          <UploadImgAndVideo
            @uploadImg="uploadImg"
            :imageUrl="item.images"
            :ids="item.file_id"
            :index="i"
          ></UploadImgAndVideo>
          <div style="margin-top: 10px">
            <el-select
              ref="select"
              @focus="handleFocus(i)"
              size="small"
              style="width: 100%"
              v-model="item.goods_title"
              multiple
              :automatic-dropdown="false"
              remote
              readonly
              placeholder="添加超链接（添加超链接（选填））"
            >
            </el-select>
          </div>
        </div>
        <div v-if="data.block_content.length < 6" class="wait" @click="addCOM">
          <i class="el-icon-plus"></i>
          添加组件({{ data.block_content.length }}/6)
        </div>
        <div style="margin-top: 20px" v-if="type == 'slide_text'">
          <div style="margin-bottom: 10px; font-size: 14px; color: #333">
            文本内容
          </div>
          <div>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="在此处输入并整理文字内容"
              maxlength="1000"
              show-word-limit
              v-model="data.block_text"
            >
            </el-input>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'multigraph'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">多张图片</div>
        <div class="desc1" style="padding: 10px 0 0">
          需要更多空间来展示您的商品？在此处添加2张图片，并把他们链接至商品详情页面或店内分类。
        </div>
      </div>
      <div class="conter">
        <div class="desc" style="padding: 10px 0 0">选择布局</div>

        <div style="display: flex; justify-content: center">
          <div
            @click="handleClick(1)"
            class="dt-item"
            :class="data.block_layout == 1 ? 'dt-active' : ''"
          >
            <div
              class="tu1"
              style="width: 38px; height: 38px"
              v-for="item in 2"
              :key="item"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </div>
          </div>
          <div
            @click="handleClick(2)"
            class="dt-item"
            :class="data.block_layout == 2 ? 'dt-active' : ''"
            style="padding: 4px"
          >
            <div
              class="tu1"
              style="width: 30px; height: 30px"
              v-for="item in 3"
              :key="item"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </div>
          </div>
          <div
            @click="handleClick(3)"
            class="dt-item"
            :class="data.block_layout == 3 ? 'dt-active' : ''"
            style="padding: 6px"
          >
            <div
              class="tu1"
              style="width: 20px; height: 20px"
              v-for="item in 4"
              :key="item"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 26 26"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="desc1">
          <span class="desc" style="margin: 20px 0 0; display: block"
            >上传图片</span
          >
          <br />
          图片:
          <br />
          · 图片大小不得超过2MB; 分辨率上限为2000 * 2000px
          <br />
          · 图片格式：JPG, JPEG, PNG, GIF
        </div>
        <div class="bj-box" v-for="(item, i) in data.block_content" :key="i">
          <UploadImgAndVideo
            @uploadImg="uploadImg"
            :imageUrl="item.images"
            :index="i"
          ></UploadImgAndVideo>
          <div style="margin-top: 10px">
            <el-select
              ref="select"
              @focus="handleFocus(i)"
              size="small"
              style="width: 100%"
              v-model="item.goods_title"
              multiple
              :automatic-dropdown="false"
              remote
              readonly
              placeholder="添加超链接（添加超链接（选填））"
            >
            </el-select>
          </div>
        </div>
        <!-- <div v-if="data.block_content.length < 4" class="wait" @click="addCOM">
          <i class="el-icon-plus"></i>
          添加组件({{ data.block_content.length }}/4)
        </div> -->
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'video'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">视频</div>
        <div class="desc1" style="padding: 10px 0 0">
          使用商品或品牌的视频吸引更多买家。
        </div>
      </div>
      <div class="conter">
        <div class="desc1">
          <span class="desc" style="margin: 20px 0 0; display: block"
            >添加视频</span
          >
          <br />

          · 视频大小不得超过30MB; 分辨率上限为1280 * 1280px
          <br />
          · 视频长度：10-60秒
          <br />
          · 视频格式： MP4
        </div>
        <UploadVideo
          @uploadVideo="uploadVideo"
          :imageUrl="data.block_content.video_img"
        ></UploadVideo>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'text'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">文字</div>
        <div class="desc1" style="padding: 10px 0 0">
          用文字分享您的店铺或品牌故事。
        </div>
      </div>
      <div class="conter">
        <div style="margin-top: 20px">
          <div style="margin-bottom: 10px; font-size: 14px; color: #333">
            文字内容
          </div>
          <div>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="在此处输入并整理文字内容"
              maxlength="1000"
              show-word-limit
              v-model="data.block_text"
            >
            </el-input>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'goods_light'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">商品亮点</div>
        <div class="desc1" style="padding: 10px 0 0">
          在此展示您最新或最畅销的商品，以带动流量及销量。
        </div>
      </div>
      <div class="conter">
        <div>
          <div style="margin-bottom: 10px; font-size: 14px; color: #333">
            展示模式
          </div>
          <div>
            <el-radio-group
              @change="goodsLightChange"
              v-model="data.block_layout"
            >
              <el-radio :label="0" style="margin-right: 140px">自动</el-radio>
              <el-radio :label="1">手动</el-radio>
            </el-radio-group>
          </div>

          <div v-if="data.block_layout == 1">
            <div style="margin: 10px 0; font-size: 14px; color: #333">
              添加商品
            </div>
            <div class="desc1">将为您挑选4个商品</div>
            <div style="margin-top: 10px">
              <div
                v-for="(item, i) in data.block_content"
                :key="i"
                style="
                  padding: 10px;
                  border: 1px solid #eee;
                  border-radius: 4px;
                  display: flex;
                  margin-bottom: 10px;
                "
              >
                <el-image
                  style="
                    min-width: 105px;
                    width: 105px;
                    margin-right: 10px;
                    height: 100px;
                  "
                  :src="item.images"
                  fit="fit"
                ></el-image>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                  "
                >
                  <div class="desc row-hidden">{{ item.title }}</div>
                  <div class="desc1" style="color: #ee4d2d">
                    RM{{ item.price }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data.block_content.length < 20"
              class="wait"
              @click="addGoods"
            >
              <i class="el-icon-plus"></i>
              选择商品({{ data.block_content.length }}/20)
            </div>
          </div>
          <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
            标题
          </div>
          <div>
            <el-input
              maxlength="40"
              show-word-limit
              size="medium"
              v-model="data.block_text"
              placeholder="最高销售"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'goods_cate'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">商品-按分类</div>
        <div class="desc1" style="padding: 10px 0 0">
          在此处展示您的热门商品。我们推荐您从您最热门的分类开始。
        </div>
      </div>
      <div class="conter">
        <div>
          <div style="margin-bottom: 10px; font-size: 14px; color: #333">
            展示模式
          </div>
          <div>
            <el-radio-group v-model="data.block_layout">
              <el-radio :label="0" style="margin-right: 140px">智能</el-radio>
              <el-radio :label="1">手动</el-radio>
            </el-radio-group>
          </div>
          <div v-if="data.block_layout === 0">
            <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
              添加商品
            </div>
            <div class="desc1">系统将自动选择一个有效的分类。</div>
            <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
              标题
            </div>
            <div class="desc1">系统将自动生成标题</div>
          </div>
          <div v-if="data.block_layout === 1">
            <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
              选择分类
            </div>
            <div>
              <el-select
                clearable
                size="medium"
                style="width: 100%"
                v-model="data.block_content.cate_name"
                @change="handleOpenClass"
                @focus="handleOpenClassFocus"
                @clear="handleClear"
                placeholder="通过选择商店分类添加商品(添加超链接（必填）)"
              >
                <el-option label="我的商店分类" value="1"> </el-option>
              </el-select>
            </div>
            <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
              标题
            </div>
            <div>
              <el-input
                maxlength="40"
                show-word-limit
                size="medium"
                v-model="data.block_text"
                placeholder="最高销售"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'goods_hot' || type == 'goods_new'">
      <div
        v-if="type == 'goods_hot'"
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">热门商品</div>
        <div class="desc1" style="padding: 10px 0 0">
          根据您选择的排序方式，在您的店铺首页展示商品。
        </div>
      </div>
      <div
        v-else
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">新商品</div>
        <div class="desc1" style="padding: 10px 0 0">
          在您的商店主页上自动展示新商品。
        </div>
      </div>
      <div class="conter">
        <div>
          <div v-if="type == 'goods_hot'">
            <div style="margin-bottom: 10px; font-size: 14px; color: #333">
              选择最佳方式
            </div>
            <div>
              <el-radio-group v-model="data.block_layout">
                <el-radio :label="0" style="margin-right: 140px"
                  >最佳销量</el-radio
                >
                <el-radio :label="1">最低价格</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div v-if="type == 'goods_hot'">
            <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
              添加商品
            </div>
            <div class="desc1">
              · 将显示最少3个和最多6个商品。
              <br />
              · 若热门商品少于3个，此组件将从您的商店主页中隐藏。
            </div>
            <div
              @click="toPush(2)"
              style="font-size: 12px; margin-top: 10px"
              class="goods-btn"
            >
              <span>在'我的商品'页面上管理您的商品</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div v-else>
            <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
              添加商品
            </div>
            <div class="desc1">
              · 新商品将展示在30天内创建且有库存的商品。
              <br />
              · 若热门商品少于3个，此组件将从您的商店主页中隐藏。
              <br />
              · 若新商品少于 2个，此组件将从您的商店主页中自动隐藏。
            </div>
            <div
              @click="toPush(2)"
              style="font-size: 12px; margin-top: 10px"
              class="goods-btn"
            >
              <span>在'我的商品'页面上管理您的商品</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div v-if="type == 'goods_hot'">
            <div style="margin: 10px 0; font-size: 14px; color: #333">标题</div>
            <div>
              <el-input
                maxlength="40"
                show-word-limit
                size="medium"
                v-model="data.block_text"
                placeholder="最高销售"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'image_cate'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">图片分类列表</div>
        <div class="desc1" style="padding: 10px 0 0">
          正在管理一个大型商店？在此处展示您的热门分类以帮助您的客户找到他们喜欢的商品。
        </div>
      </div>
      <div class="conter">
        <div>
          <div style="margin-bottom: 10px; font-size: 14px; color: #333">
            展示模式
          </div>
          <div>
            <el-radio-group
              @change="imageCateChange"
              v-model="data.block_layout"
            >
              <el-radio :label="0" style="margin-right: 140px">自动</el-radio>
              <el-radio :label="1">手动</el-radio>
            </el-radio-group>
          </div>
          <div v-if="data.block_layout == 0">
            <div class="desc" style="margin: 20px 0 10px">自动模式</div>
            <div class="desc1">展示的分类和您店铺首页的分类相同</div>
          </div>
          <div v-if="data.block_layout == 1">
            <div class="desc" style="margin: 20px 0 10px">手动模式</div>
            <div class="desc1">
              · 图片宽度：300px; 图片高度：300px
              <br />
              · 图片大小最大为2.0 MB
              <br />
              · 图片格式已被接受: JPG, JPEG, PNG
              <br />
              · 您的商店仅会显示有可用库存的商品分类。
            </div>
            <div>
              <div
                class="bj-boxs"
                v-for="(item, i) in data.block_content"
                :key="i"
              >
                <UploadImgAndVideo2
                  @uploadImg="uploadImg"
                  :imageUrl="item.images"
                  :ids="item.file_id"
                  :index="i"
                ></UploadImgAndVideo2>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    margin-left: 10px;
                  "
                >
                  <el-select
                    clearable
                    size="small"
                    style="width: 100%"
                    v-model="item.category_name_z"
                    @change="handleOpenClass1(i)"
                    @focus="handleOpenClassFocus1(i)"
                    placeholder="添加超链接"
                  >
                    <el-option label="我的商店分类" value="1"> </el-option>
                  </el-select>
                  <el-input
                    style="width: 100%"
                    size="small"
                    v-model="item.category_name"
                    placeholder="输入分类名称"
                  ></el-input>
                </div>
              </div>
              <div
                v-if="data.block_content.length < 9"
                class="wait"
                @click="addCOM"
              >
                <i class="el-icon-plus"></i>
                添加新分类({{ data.block_content.length }}/9)
              </div>
            </div>
          </div>
          <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
            标题
          </div>
          <div>
            <el-input
              maxlength="40"
              show-word-limit
              size="medium"
              v-model="data.block_text"
              placeholder="最高销售"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="type == 'hot_promotion'">
      <div
        style="
          border-bottom: 1px solid #e5e5e5;
          padding: 20px;
          margin-bottom: 10px;
        "
      >
        <div class="title">热门促销</div>
        <div class="desc1" style="padding: 10px 0 0">
          在商店主页针对不同的买家展示不同的折扣商品。
        </div>
      </div>
      <div class="conter">
        <div>
          <div style="margin-bottom: 10px; font-size: 14px; color: #333">
            展示模式
          </div>
          <div>
            <el-radio-group
              @change="goodsLightChange1"
              v-model="data.block_layout"
            >
              <el-radio :label="0" style="margin-right: 140px">智能</el-radio>
              <el-radio :label="1">手动</el-radio>
            </el-radio-group>
          </div>

          <div>
            <div style="margin: 10px 0; font-size: 14px; color: #333">
              添加商品
            </div>
            <div class="desc1" v-if="data.block_layout === 0">
              · 热门促销是在折扣活动中，且有库存的商品。基于商品在Shopee
              的受欢迎度进行选择。
              <br />
              · 根据买家的购买行为，他们可能会看到不同的商品。
              <br />
              · 将显示最少3个和最多6个商品。
              <br />
              · 如果推荐的热门促销少于3个，该组件将在您的商店主页自动隐藏。
            </div>
            <div class="desc1" v-if="data.block_layout === 1">
              · 只有正在进行中的折扣商品会在您的店铺首页展示。
              <br />
              · 你可以选择3 到 6个折扣商品。
              <br />
              · 如果推荐的热门促销少于 3 个，该组件将在您的商店主页自动隐藏。
            </div>
            <div
              @click="toPush(1)"
              style="font-size: 12px; margin-top: 10px"
              class="goods-btn"
            >
              <span>管理你的打折促销</span>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div v-if="data.block_layout == 1">
              <div style="margin-top: 10px">
                <div
                  v-for="(item, i) in data.block_content"
                  :key="i"
                  style="
                    padding: 10px;
                    border: 1px solid #eee;
                    border-radius: 4px;
                    display: flex;
                    margin-bottom: 10px;
                  "
                >
                  <el-image
                    style="
                      min-width: 105px;
                      width: 105px;
                      margin-right: 10px;
                      height: 100px;
                    "
                    :src="item.goods_images"
                    fit="fit"
                  ></el-image>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      flex-direction: column;
                    "
                  >
                    <div class="desc row-hidden">{{ item.goods_title }}</div>
                    <div class="desc1" style="color: #ee4d2d">
                      RM{{ item.goods_price }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="data.block_content.length < 6"
                class="wait"
                @click="addDiscGoods"
              >
                <i class="el-icon-plus"></i>
                选择打折商品({{ data.block_content.length }}/6)
              </div>
            </div>
          </div>
          <div style="margin: 20px 0 10px; font-size: 14px; color: #333">
            标题
          </div>
          <div>
            <el-input
              maxlength="40"
              show-word-limit
              size="medium"
              v-model="data.block_text"
              placeholder="最高销售"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button
          @click="submit"
          type="primary"
          size="medium"
          style="min-width: 80px"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import UploadImgAndVideo from "./RightForm/UploadImgAndVideo.vue";
import UploadImgAndVideo2 from "./RightForm/UploadImgAndVideo2.vue";
import UploadVideo from "./RightForm/UploadVideo.vue";

export default {
  components: {
    UploadImgAndVideo,
    UploadImgAndVideo2,
    UploadVideo,
  },
  name: "RightForm",
  props: ["type", "data", "index"],
  data() {
    return {
      input: "1231",
      tabPosition: "",
    };
  },
  mounted() {},
  methods: {
    asd() {
      return false;
    },
    // 打开商品列表
    handleFocus(i) {
      sessionStorage.FORMACTIVEINDEX = i;
      this.$parent.$parent.openPop();
    },
    // 图片上传
    uploadImg(val) {
      let arr = JSON.stringify(this.$props.data.block_content);
      arr = JSON.parse(arr);
      arr[val.index].image = val.data.short_url;
      arr[val.index].images = val.data.url;
      arr[val.index].file_id = val.data.id;
      this.$props.data.block_content = arr;
    },
    // 视频上传
    uploadVideo(val) {
      let obj = JSON.stringify(this.$props.data.block_content);
      obj = JSON.parse(obj);
      obj.video_cover = val.video_short_img;
      obj.video = val.short_url;
      obj.video_short = val.url;
      obj.video_img = val.video_img;
      obj.file_id = val.id;
      this.$props.data.block_content = obj;
    },
    // 媒体组件添加组件
    addCOM() {
      this.$props.data.block_content.push({
        file_id: 0,
        image: "",
        goods_id: [],
        goods_title: [],
      });
    },
    // 商品亮点添加商品
    addGoods() {
      this.$parent.$parent.openPop();
    },
    addDiscGoods() {
      this.$parent.$parent.openPopDisc();
    },
    // 提交数据保存
    submit() {
      let goodsType = true;
      let imgType = true;
      let videoType = true;
      if (this.$props.data.block_content.constructor === Array) {
        this.$props.data.block_content.forEach((item) => {
          if (item.image == "") {
            imgType = false;
          }
          if (item.goods_id == "") {
            goodsType = false;
          }
        });
      }
      if (this.$props.data.block_content.constructor === Object) {
        if (this.$props.data.block_content.video == "") {
          videoType = false;
        }
      }
      if (this.$props.data.block_module_type != "image_cate") {
        if (!imgType) {
          this.$message.warning("图片不能为空");
          return false;
        }
      }

      // if (!goodsType) {
      //   this.$message.warning("链接不能为空");
      //   return false;
      // }
      if (!videoType) {
        this.$message.warning("视频不能为空");
        return false;
      }
      this.$parent.$parent.submit(this.$props.index);
    },
    // 多图组件切换
    handleClick(index) {
      if (this.$props.data.block_layout === index) return;

      let arr = JSON.stringify(this.$props.data.block_content);
      arr = JSON.parse(arr);
      if (index - this.$props.data.block_layout > 0) {
        for (let i = 0; i < index - this.$props.data.block_layout; i++) {
          arr.push({
            file_id: 0,
            image: "",
            goods_id: [],
            goods_title: [],
          });
        }
      } else {
        for (let i = 0; i < this.$props.data.block_layout - index; i++) {
          arr.splice(arr.length - 1, 1);
        }
      }
      this.$props.data.block_content = arr;
      this.$props.data.block_layout = index;
    },
    // 打开分类弹窗
    handleOpenClass() {
      let text = this.$props.data.block_content.cate_name;

      if (text != "") {
        this.$parent.$parent.handleOpenClass();
        this.$props.data.block_content.cate_name = "";
      } else {
        this.$props.data.block_content.cate_name_log = "";
      }
    },
    handleOpenClassFocus() {
      let text = this.$props.data.block_content.cate_name;
      if (text) {
        this.$props.data.block_content.cate_name_log = text;
      }
    },

    // 图片分类列表打开分类弹窗
    handleOpenClass1(index) {
      let text = this.$props.data.block_content[index].category_name_z;

      if (text != "") {
        sessionStorage.IMAGECATEINDEX = index;
        this.$parent.$parent.handleOpenClass();
        this.$props.data.block_content[index].category_name_z = "";
      } else {
        this.$props.data.block_content[index].category_name_log = "";
      }
    },
    handleOpenClassFocus1(index) {
      let text = this.$props.data.block_content[index].category_name_z;
      if (text) {
        this.$props.data.block_content[index].category_name_log = text;
      }
    },
    // 图片分类列表展示模式
    imageCateChange(val) {
      let arr = [];
      let num = val == 0 ? 4 : 9;
      for (let i = 0; i < num; i++) {
        arr.push({
          category_id: 0,
          category_name: "",
          category_name_log: "",
          category_name_z: "",
          file_id: 0,
          image: "",
          images: "",
        });
      }
      this.$props.data.block_content = arr;
    },
    // 商品亮点更换模式
    goodsLightChange() {
      let arr = [];
      for (let i = 0; i < 4; i++) {
        arr.push({
          file_id: 0,
          image: "",
          images: "",
          goods_id: "",
          title: "",
          price: 0,
        });
      }
      this.$props.data.block_content = [];
      this.$props.data.goodsList = arr;
    },
    // 热门促销更换模式
    goodsLightChange1() {
      let arr = [];
      for (let i = 0; i < 6; i++) {
        arr.push({
          discount_id: 0,
          goods_id: "",
          goods_title: "",
          goods_image: "",
          goods_images: "",
          goods_price: "",
          discount_price: "",
          discount_ration: "",
        });
      }
      this.$props.data.block_content = [];
      this.$props.data.goodsList = arr;
    },
    toPush(type) {
      if (type == 1) {
        window.open("/discount", "_blank");
      }
      if (type == 2) {
        this.$store.state.path = "myGoods";
        window.open("/myGoods", "_blank");
      }
    },

    handleClear() {
      this.$props.data.block_content = {
        cate_id: "",
        cate_name: "",
        goods_list: [],
      };
      this.$props.data.block_text = "";
    },
  },
};
</script>
<style lang="less">
.rightForm {
  width: 400px;
  background: #fff;
  position: relative;
  padding-bottom: 60px;
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    color: #333;
  }
  .desc1 {
    font-size: 12px;
    color: #333;
  }
  .goods-btn {
    color: #2673dd;
    cursor: pointer;
  }
  .goods-btn:hover {
    color: #0046ab;
  }
  .conter {
    max-height: 400px;
    min-height: 100px;
    overflow: scroll;
    padding: 0 20px 20px;
    .bj-box {
      padding: 10px;
      background: #f6f6f6;
      margin-top: 10px;
    }
    .bj-boxs {
      padding: 10px;
      background: #f6f6f6;
      margin-top: 10px;
      display: flex;
    }
    .wait {
      margin-top: 10px;
      background: #fff;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      color: #2673dd;
      border: 1px dashed #2673dd;
      border-radius: 4px;
    }
    .wait:hover {
      background: #2673dd0a;
      cursor: pointer;
    }

    // 多图选择样式
    .dt-item {
      width: 117px;
      height: 70px;
      border: 1px solid #eee;
      margin-right: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      .tu1 {
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: #b7b7b7;
      }
    }
    .dt-active {
      border: 1px solid #2673dd;
      padding: 10px;
      .tu1 {
        background: #e5eefb;
        display: flex;
        justify-content: center;
        fill: #2673dd;
      }
    }
  }

  .bottom-btn {
    display: flex;
    justify-content: right;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 60px;
    padding-right: 20px;
    align-items: center;
    z-index: 2;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #2673dd1f;
    border-color: #2673dd;
    box-shadow: -1px 0 0 0 #2673dd;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #2673dd;
  }
  .el-radio-button__inner:hover {
    color: #2673dd;
  }
}
</style>