<template>
  <el-dialog
    :before-close="handleClose"
    title="选择系统默认分类"
    :visible.sync="show"
  >
    <div style="margin-top: -30px">分类页将显示系统默认分类</div>
    <div style="border: 1px solid #eee; border-radius: 4px; margin-top: 20px">
      <el-table
        :header-cell-style="{
          background: '#f6f6f6',
        }"
        :data="gridData"
      >
        <el-table-column width="80" align="center" property="date">
          <template slot-scope="scope">
            <el-radio
              v-model="value"
              :label="scope.row.category_id"
              v-if="scope"
            >
              &ensp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          property="name"
          label="分类"
        ></el-table-column>
        <el-table-column
          align="center"
          property="count"
          label="商品"
        ></el-table-column>
        <el-table-column align="center" property="address" label="操作">
          <template slot-scope="scope">
            <div v-if="scope" class="info-btn" @click="topPush">查看详情</div>
          </template></el-table-column
        >
      </el-table>
    </div>
    <div style="margin-top: 20px; display: flex; justify-content: right">
      <el-button @click="handleClose" style="min-width: 80px" size="medium"
        >取消</el-button
      >
      <el-button
        @click="handleClick"
        style="min-width: 80px"
        size="medium"
        type="primary"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { getCategoryGoods } from "@/api/shopGoods.js";
export default {
  name: "CategoryList",
  props: ["show"],
  data() {
    return {
      gridData: [],
      value: "",
    };
  },
  created() {
    getCategoryGoods().then((res) => {
      if (res.code == 1) {
        this.gridData = res.data;
      }
    });
  },
  methods: {
    handleClose() {
      this.$parent.handleClose();
    },
    handleClick() {
      this.gridData.forEach((item) => {
        if (item.category_id === this.value) {
          this.$parent.handleAndClass(item);
        }
      });
    },
    topPush(){
      window.open('myGoods')
    }
  },
};
</script>
<style lang="less" scoped>
.info-btn {
  color: #2673dd;
  cursor: pointer;
}
.info-btn:hover {
  color: #0046ab;
}
</style>