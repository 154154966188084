import request from '@/api/request.js';
// 添加（编辑）商品
export function operatorGoods(data) {
    return request({
        url: 'business/api/goods/operatorGoods',
        method: 'post',
        data: data
    });
}
// 商品详情
export function goodsFind(data) {
    return request({
        url: 'business/api/goods/find',
        method: 'post',
        data: data
    });
}
// 获取商品列表
export function goodsList(data) {
    return request({
        url: 'business/api/goods/list',
        method: 'post',
        data: data
    });
}
// 操作商品  type=1.删除2.上下架   status=0.下架(删除)1.上架(不删除)
export function operationGoods(data) {
    return request({
        url: 'business/api/goods/action',
        method: 'post',
        data: data
    });
}


// Facebook管理 - 1-获取文章评论列表

export function getArticleList(data) {
    return request({
        url: 'business/api/facebook/getArticleList',
        method: 'get',
        params: data
    });
}
// Facebook管理 - 2-发布文章/帖子
export function submitArticle(data) {
    return request({
        url: 'business/api/facebook/submitArticle',
        method: 'post',
        data: data
    });
}

// Facebook管理 - 3-删除文章/帖子

export function delArticle(data) {
    return request({
        url: 'business/api/facebook/delArticle',
        method: 'delete',
        data: data
    });
}

// Facebook管理 - 4-回复评论
export function replyContent(data) {
    return request({
        url: 'business/api/facebook/replyContent',
        method: 'post',
        data: data
    });
}

// 商品管理 - 1.7-获取全球商品列表

export function getGoodsCommonList(data) {
    return request({
        url: 'business/api/goods/getGoodsCommonList',
        method: 'post',
        data: data
    });
}


// 商品管理 - 1.7-搜索店铺列表
export function shopSearch(data) {
    return request({
        url: 'business/api/shop/search',
        method: 'post',
        data: data
    });
}


// 商品管理 - 1.8-添加(编辑)全球商品
export function subGoodsCommon(data) {
    return request({
        url: 'business/api/goods/subGoodsCommon',
        method: 'post',
        data: data
    });
}


// 商品管理 - 1.9-发布全球商品

export function publishGoods(data) {
    return request({
        url: 'business/api/goods/publishGoods',
        method: 'post',
        data: data
    });
}

// 商品管理 - 1.9-发布全球商品

export function delGoods(data) {
    return request({
        url: 'business/api/goods/delGoods',
        method: 'delete',
        data: data
    });
}


// 商品管理 - 2.1-获取分类商品
export function getCategoryGoods(data) {
    return request({
        url: 'business/api/goods/getCategoryGoods',
        method: 'get',
        params: data
    });
}


// 商店管理 - 2.1-获取折扣商品列表



export function getDiscountGoodsList(data) {
    return request({
        url: 'business/api/shop/getDiscountGoodsList',
        method: 'post',
        data: data
    });
}